var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-title",
        [
          _c("barcode-scanner", {
            attrs: {
              search: _vm.scanCode,
              scannerPayload: _vm.scannerPayload,
              loading: _vm.respectiveViewLoading,
            },
            model: {
              value: _vm.scanCode,
              callback: function ($$v) {
                _vm.scanCode = $$v
              },
              expression: "scanCode",
            },
          }),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-btn",
            {
              staticClass: "text-center mt-5",
              attrs: { color: "primary", block: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("supplier_orders")
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("message.whsDashboard.receiveSupplierOrder")) +
                  " "
              ),
              _vm.loading
                ? _c("v-icon", { staticClass: "mdi-spin ml-2" }, [
                    _vm._v("mdi-loading"),
                  ])
                : [_vm._v("(" + _vm._s(_vm.supplierOrders) + ")")],
            ],
            2
          ),
          _c(
            "v-btn",
            {
              staticClass: "text-center mt-5",
              attrs: { color: "primary", block: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("transfer_orders")
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("message.whsDashboard.transferOrders")) +
                  " "
              ),
              _vm.loading
                ? _c("v-icon", { staticClass: "mdi-spin ml-2" }, [
                    _vm._v("mdi-loading"),
                  ])
                : [_vm._v("(" + _vm._s(_vm.transferOrders) + ")")],
            ],
            2
          ),
          _c(
            "v-btn",
            {
              staticClass: "text-center mt-5",
              attrs: { color: "primary", block: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("adjustment_orders")
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("message.whsDashboard.adjustmentOrders")) +
                  " "
              ),
              _vm.loading
                ? _c("v-icon", { staticClass: "mdi-spin ml-2" }, [
                    _vm._v("mdi-loading"),
                  ])
                : [_vm._v("(" + _vm._s(_vm.adjustmentOrders) + ")")],
            ],
            2
          ),
          _c(
            "v-btn",
            {
              staticClass: "text-center mt-5",
              attrs: { color: "primary", block: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("cycle_count")
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("message.whsDashboard.cycleCount")) + " "
              ),
              _vm.loading
                ? _c("v-icon", { staticClass: "mdi-spin ml-2" }, [
                    _vm._v("mdi-loading"),
                  ])
                : [_vm._v("(" + _vm._s(_vm.cycleCount) + ")")],
            ],
            2
          ),
          _c(
            "v-btn",
            {
              staticClass: "text-center mt-5",
              attrs: { color: "primary", block: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("product_view")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("message.whsDashboard.product")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }